import { Switch } from "antd"

const SwitchCmp = ({ onChange, value, data = {} }) => {
    return (
        <Switch
            style={{ backgroundColor: value == 1 ? 'black' : "gray" }}
            size='small'
            value={value == 1 ? true : false}
            onChange={(e) => onChange(e, data)}
        />
    )
}

export default SwitchCmp