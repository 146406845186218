import React, { useEffect, useState } from 'react'
import HeaderCmp from '../../components/HeaderCmp';
import SliderCmp from '../../components/SliderCmp';
import PriceToPont from './PriceToPont';
import { Col, Row } from 'antd';

const Setting = () => {
    return (

        <div>
            <HeaderCmp />
            <div className='d-flex'>
                <SliderCmp />
                <div className='p-5 w-100 '>
                    <div className='d-flex' style={{ backgroundColor: "#282A3A", padding: 15, borderRadius: 10, paddingTop: 20, }}>
                        <h5 style={{ color: "white", paddingLeft: 15 }}>Setting</h5>
                    </div>
                    <div className='p-4'>
                        <Row>
                            <Col span={6}>
                                <PriceToPont />
                            </Col>
                            <Col span={12}>

                            </Col>
                        </Row>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default Setting