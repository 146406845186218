import { toast } from "react-toastify";

export const successMsg = (msg) => {
    toast.success(msg, {
        position: "top-center"
    });
}

export const errorMsg = (msg) => {
    toast.error(msg, {
        position: "top-center"
    });
}