
import { Space, Table, Popconfirm, Image, Switch } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { ApiServices } from '../../services/apiServices';
import { ApiConfig } from '../../services/ApiConfigs';
import SwitchCmp from '../../components/SwitchCmp';

const DataTable = ({ data, deleteBanner, updateBanner, loader }) => {

    const onChangeBanner = async (checked: boolean, data: any) => {
        console.log(`switch to ${checked}`);
        updateBanner(checked, data)
    };

    const columns = [
        {
            title: 'Image',
            dataIndex: 'UploadMedium',
            key: 'UploadMedium',
            render: (image) => {
                return <Image
                    src={image?.url ? image?.url : "./image/logo.png"}
                    width={60}
                    height={60}
                />
            }
            // render: (UploadMedium) => <img src={UploadMedium?.url} className='img-fluid shadow-4' alt='...' style={{ height: 50, width: 50, }} />,
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'name',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_: any, record: any) => (
                <Space size="middle">
                    <SwitchCmp
                        onChange={onChangeBanner}
                        value={record.status}
                        data={record}
                    />
                    <Popconfirm
                        title="Delete the task"
                        description="Are you sure to delete this task?"
                        icon={
                            <QuestionCircleOutlined
                                style={{
                                    color: 'red',
                                }}
                            />
                        }
                        onConfirm={() => deleteBanner(record.id)}
                    >
                        <img src='./image/delete.png' className='img-fluid shadow-4' alt='...' style={{ height: 20, width: 20, }} />
                    </Popconfirm>
                </Space >
            ),
        },
    ];

    return (
        <Table columns={columns} dataSource={data} loading={loader} />
    )
}

export default DataTable