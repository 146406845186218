import React, { useEffect, useState } from 'react';
import { Button, Col, Image, Input, Modal, Cascader, Radio, Row } from 'antd';
import { ApiServices } from '../../services/apiServices';
import { ApiConfig } from '../../services/ApiConfigs';
import PDFViewer from 'pdf-viewer-reactjs';
import { errorMsg, successMsg } from '../../utils/ShowMessage';

const EditPlaylist = ({ isModalOpen, setIsModalOpen, flow, data, getPlaylists, editData }) => {

    const playlistData = editData?.PlaylistSongs || []
    const [playlistPriceValue, setPlaylistPriceValue] = useState(1);
    const [inputValue, setInputValue] = useState("")
    const [categoriesData, setCategoriesData] = useState([]);
    const [selectCategories, setSelectCategories] = useState([]);
    const [points, setPoints] = useState("");

    useEffect(() => {
        console.log(editData, "test editData")
        setPoints(editData.point)
        setPlaylistPriceValue(editData.type == "paid" ? 2 : 1)
        setInputValue(editData.title || "")
    }, [editData])

    const [catData, setCatData] = useState([]);

    const playlistAddCategories = () => {
        try {
            let catId = catData.filter((res) => res.id == selectCategories[0])
            console.log(catId[0].id, "catDarta")
            ApiServices('post', ApiConfig.PLAYLIST_ADD_CATEGORIES, { categories_id: selectCategories, Playlists: [editData?.id] }).then((res) => {
                let tmp = res
                console.log(tmp, "Edit PLAY_LIST response..");
                getPlaylists()
            })
        } catch (error) {
            console.log(error)
        }
    }

    const handleOk = () => {
        console.log(playlistPriceValue, "<==>", points)
        if (playlistPriceValue == 2 && points == "") {
            errorMsg("Please Enter points..")
            return
        }
        //  else if (points == 0) {
        //     errorMsg("Please Enter valid points..")
        //     return
        // }

        // setIsModalOpen(false);
        // if (selectCategories.length > 0) {
        //     playlistAddCategories()
        // }

        const body = {
            title: inputValue,
            point: playlistPriceValue == 1 ? "0" : points,
            type: playlistPriceValue == 2 ? "paid" : "free",
        }
        console.log(body, "edit playlist..")
        console.log(ApiConfig.GET_PLAYLIST + editData?.id);
        try {
            ApiServices('put', ApiConfig.GET_PLAYLIST + editData?.id, body).then((res) => {
                let tmp = res
                console.log(tmp, "Edit PLAY_LIST response..");
                setIsModalOpen(false)
                getPlaylists()
            })
        } catch (error) {
            console.log(error)
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const DisplayItems = () => {
        return (
            <>
                <Row gutter={10}>
                    {
                        playlistData.map((res, index) => {
                            if (res?.thumbnailMedia?.url) {
                                console.log(res?.thumbnailMedia?.url, "res?.thumbnailMedia?.url")
                            }

                            if (res.UploadMedium.type == "image/jpeg" || res.UploadMedium.type == "image/jpg" || res.UploadMedium.type == "image/png") {
                                return <Col className='gutter' span={8}>
                                    <ImgViews src={res.UploadMedium.url} />
                                </Col>

                            } else if (res.UploadMedium.type == "video/mp4") {
                                return <Col span={8}>
                                    <VideoViews
                                        url={res?.UploadMedium?.url}
                                        poster={res?.thumbnailMedia?.url}
                                    />
                                </Col>
                            } else if (res.UploadMedium.type == "application/pdf") {
                                return <Col span={8}>
                                    <RdfViews
                                        url={res?.UploadMedium?.url}
                                        poster={res?.thumbnailMedia?.url}
                                    />
                                </Col>
                            }
                        })
                    }

                </Row>
            </>
        )
    }

    const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);

    const showModal = () => {
        setIsPdfModalOpen(true);
    };

    const handlePdf = () => {
        setIsPdfModalOpen(false);
    };

    const handlePdfCancel = () => {
        setIsPdfModalOpen(false);
    };

    const RdfViews = ({ url, poster }) => {
        return (
            <>
                <img
                    onClick={showModal}
                    src={poster}
                    width={200}
                    height={150}
                />

                <Modal width={650} title="PDF View" open={isPdfModalOpen} onOk={handlePdf} onCancel={handlePdfCancel}>
                    <PDFViewer
                        css="asdf-wrapper"
                        canvasCss="asdf-canvas"
                        document={{
                            url: url,
                        }}
                    />
                </Modal>
            </>
        )
    }

    const ImgViews = ({ src }) => {
        return (
            <Image
                width={200}
                height={150}
                src={src}
            />
        )
    }

    const VideoViews = ({ url, poster }) => {
        console.log(poster, "poster")
        return (
            <>
                {
                    poster ?
                        <>
                            <video controls width={200} height={150} poster={poster}>
                                <source src={url} type="video/webm" />
                            </video>
                        </>
                        :
                        <video controls width={200} height={150}>
                            <source src={url} type="video/webm" />
                        </video>
                }
            </>
        )
    }

    const onChange = (value) => {
        console.log(value, "playlist id...");
        setSelectCategories(value)
    };

    useEffect(() => {
        getCategories()
    }, [])



    const getCategories = async () => {
        try {
            await ApiServices('get', ApiConfig.CATEGORIES).then((res) => {
                let tmp = res?.data?.data
                setCatData(tmp)
                console.log(tmp, "ALL_CATEGORIES response..");
                var CascadeOptions = []
                tmp.map((res) => {
                    CascadeOptions.push({ value: res.id, label: res.title, })
                })
                if (tmp) {
                    setCategoriesData(CascadeOptions)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    const onChangePlaylistPrice = (e) => {
        console.log('radio checked', e.target.value);
        setPlaylistPriceValue(e.target.value);
    };

    return (
        <>
            <Modal
                width={700}
                title={flow == "edit" ? "Edit Playlist" : "View Playlist"} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                {
                    flow == "edit" && data?.[0]?.title ?
                        <div className='border rounded-lg p-3'>
                            <p style={{ fontWeight: "bold" }}>Playlist Name:</p>
                            <Input placeholder="Title" value={inputValue} onChange={(e) => setInputValue(e.target.value)} />

                            <div className=' w-[100rem]'>
                                {/* <p
                                    style={{ fontWeight: "bold" }}
                                    className='font-semibold text-gray-900'>Select Categories:</p> */}

                                {/* <Cascader
                                    style={{
                                        width: "100%"
                                    }}
                                    options={categoriesData}
                                    dropdownMenuColumnStyle={{
                                        width: "100%"
                                    }}
                                    onChange={onChange} placeholder="Please select Categories" /> */}

                                <div className='pt-4'>
                                    <p style={{ fontWeight: "bold" }}
                                        className='font-semibold text-gray-900'>Select Type:</p>

                                    <Radio.Group onChange={onChangePlaylistPrice} value={playlistPriceValue}>
                                        <Radio value={1}>Free</Radio>
                                        <Radio value={2}>Paid</Radio>
                                    </Radio.Group>

                                    {
                                        playlistPriceValue == 2 ?
                                            <div className='pt-3'>
                                                <Input
                                                    value={points}
                                                    onChange={(e) => {
                                                        setPoints(e.target.value)
                                                    }} type="number" prefix="Point:" placeholder="Enter Points" />
                                            </div>
                                            : <></>
                                    }

                                </div>

                            </div>
                        </div> :
                        <DisplayItems />
                }
            </Modal>
        </>
    );
};

export default EditPlaylist;