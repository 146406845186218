import { ActionTypes } from "../constants/action-types";
const intialState = {
  userData: [],
};

export const UserDataReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_USER_DATA:
      return { ...state, userData: payload };
    case ActionTypes.LOGOUT:
      return state = undefined;
    default:
      return state;
  }
};