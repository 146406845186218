import { ApiConfig } from "./ApiConfigs";
import store from "../redux/store";

export const ApiServices = async (method, url, data = {}, headers = {}) => {
    let token = store.getState().UserData.userData.token
    let methodCustom = method.toUpperCase();
    var requestOptions = {
        method: methodCustom,
        headers: {
            ...headers,
            'Content-Type': "application/json",
            'Authorization': 'Bearer ' + token
        }
    };
    if (method.toUpperCase() != "GET" && method.toUpperCase() != "HEAD") {
        requestOptions.body = JSON.stringify(data);
    }
    var res = await fetch(url, requestOptions);
    var json = await res.json();
    return json;
}


export function uploadDocument(url, title) {
    let token = store.getState().UserData.userData.token
    const data = new FormData();
    data.append("file", url);
    data.append("title", title);
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token
        },
        body: data,
        redirect: 'follow'
    };
    return fetch(ApiConfig.UPLOAD, requestOptions)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error('There was an error!', error);
            throw error;
        });
}
