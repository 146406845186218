
import React, { useEffect, useState } from 'react';
import { Space, Table, Tag, Popconfirm, Image, Row, Col } from 'antd';
import { EditOutlined, EyeFilled, EyeOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { ApiConfig } from '../../services/ApiConfigs';
import { ApiServices } from '../../services/apiServices';
import { errorMsg, successMsg } from '../../utils/ShowMessage';
import SwitchCmp from '../../components/SwitchCmp';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../utils/routes.config';
import Search from 'antd/es/input/Search';

const PlayListTable = ({ loader, data, getPlaylists, isModalOpen, setIsModalOpen, setFlow, setEditData, updatePlaylist, setData }) => {

    const navigate = useNavigate();
    const [value, setValue] = useState('');

    const onChangeplaylist = async (checked: boolean, data: any) => {
        console.log(`switch to ${checked}`);
        updatePlaylist(checked, data)
    };

    const deleteUser = (id: any) => {
        console.log(id, "Delete api call");
        try {
            ApiServices('delete', ApiConfig.GET_PLAYLIST + id).then((res: any) => {
                let tmp = res
                console.log(tmp, "delete PLAY_LIST response..");
                if (res.status == 200) {
                    getPlaylists()
                    successMsg("Playlist Delete successfully.")
                } else {
                    errorMsg("Some thing went wrong")
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id - b.id,
            render: (id, record, index) => { ++index; return index; },
            showSorterTooltip: false,
        },
        {
            title: 'Image',
            dataIndex: 'UploadMedium',
            key: 'UploadMedium',
            // render: (image) => {
            //     return <img src={image?.url ? image?.url : "./image/logo.png"} className='img-fluid shadow-4' alt='...' style={{ height: 50, width: 50, }} />
            // }
            render: (image) => {
                return <Image
                    src={image?.url ? image?.url : "./image/logo.png"}
                    width={60}
                    height={60}
                />
            }
        },
        {
            title: 'Playlist Title',
            dataIndex: 'title',
            key: 'title',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Teacher Name',
            dataIndex: 'User',
            key: 'User',
            render: (text) => <a>{text?.firstname} {text?.lastname}</a>,
        },
        {
            title: 'Address',
            dataIndex: 'User',
            key: 'User',
            render: (text) => <a>{text?.address}</a>,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Space size="middle">

                        <EyeOutlined onClick={() => {
                            navigate(routes.MEDIA_VIEW, { state: record })
                            console.log(record, "record")
                            // setFlow("view")
                            // setIsModalOpen(true)
                            // setEditData(record)
                        }} />

                        <EditOutlined onClick={() => {
                            setEditData(record)
                            console.log(record)
                            setFlow("edit")
                            setIsModalOpen(true)
                        }} />

                        <SwitchCmp
                            onChange={onChangeplaylist}
                            value={record.status}
                            data={record}
                        />

                        <Popconfirm
                            title="Delete the task"
                            description="Are you sure to delete this task?"
                            icon={
                                <QuestionCircleOutlined
                                    style={{
                                        color: 'red',
                                    }}
                                />
                            }
                            onConfirm={() => deleteUser(record.id)}
                        >
                            <img src='./image/delete.png' className='img-fluid shadow-4' alt='...' style={{ height: 20, width: 20, }} />
                        </Popconfirm>
                    </Space >
                </Space >
            ),
        },
    ];
    const [searchData, setSearchData] = useState([]);

    return (
        <>
            <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                <Col span={6}>
                    <div className='py-3' >
                        <Search
                            className='flex justify-end'
                            placeholder="Search"
                            enterButton
                            onChange={e => {
                                const currValue = e.target.value.toLowerCase(); // Convert input to lowercase
                                setValue(currValue)
                                const filteredData = data.filter(entry =>
                                    entry.title.toLowerCase().includes(currValue) // Convert title to lowercase
                                );
                                if (currValue) {
                                    setSearchData(filteredData);
                                } else {
                                    setSearchData([]);
                                }
                            }}
                        />
                    </div>
                </Col>
            </Row>
            <Table columns={columns} dataSource={value ? searchData : data} loading={loader} />
        </>
    )
}

export default PlayListTable