import { Avatar } from 'antd';
import Navbar from 'react-bootstrap/Navbar';
import { UserOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import { setUserData } from '../redux/actions/DataActions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { successMsg } from '../utils/ShowMessage';



function HeaderCmp() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const items = [
        {
            label: (
                <h6 onClick={() => {
                    dispatch(setUserData([]));
                    navigate('/');
                    successMsg("Success logout!")
                }}> Logout</h6>
            ),
            key: '0',
        },
    ];
    return (
        <Navbar collapseOnSelect expand="lg" style={{ backgroundColor: "#333" }}>
            <div style={{
                display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: "100%",

            }}>
                <div>
                    <h3 style={{ color: 'white', marginLeft: '25px' }}>Sadhna</h3>
                </div>
                {/* <div>
                    <img src='./assets/image/logo.png' > </img>
                </div> */}
                {/* <Col xs={6} md={4}>
                    <Image src="holder.js/171x180" roundedCircle />
                </Col> */}


                <div>
                    <Dropdown menu={{ items }} trigger={['click']}>
                        <a onClick={(e) => {
                            e.preventDefault()
                        }}>
                            <Space>
                            </Space>
                            <div className="avatar" style={{ background: "#333" }}>
                                <Avatar shape="square" size={64} icon={<UserOutlined />} />
                            </div>
                        </a>
                    </Dropdown>
                </div>
            </div>

        </Navbar >
    );
}

export default HeaderCmp;