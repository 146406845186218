import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import styles from "./style.module.css";
import Nav from 'react-bootstrap/Nav';
import HeaderCmp from '../../components/HeaderCmp';
import SliderCmp from '../../components/SliderCmp';
import UserCount from './UserCount';
import PaymentChart from './PaymentChart';
import PointHistoryChart from './PointHistoryChart';
import { Card, Col, Row } from 'antd';


const Dashboard = () => {
  return (
    <div>
      <HeaderCmp />
      <div className='d-flex'>
        <SliderCmp />
        <div className='p-5 w-100 '>
          <div className='d-flex' style={{ backgroundColor: "#282A3A", padding: 15, borderRadius: 10, paddingTop: 20, }}>
            <h5 style={{ color: "white", paddingLeft: 15 }}>Dashboard</h5>
          </div>

          <div className='p-4'>
            <UserCount />
            <Row gutter={2}>
              <Col span={12}>
                <PointHistoryChart />
              </Col>
              <Col span={12}>
                <PaymentChart />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Dashboard