import React, { useEffect, useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
} from "chart.js";
import dayjs from "dayjs";
import { ApiServices } from '../../services/apiServices';
import { ApiConfig } from '../../services/ApiConfigs';

ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Tooltip, Legend);

const PointHistoryChart = () => {
    const [loader, setLoader] = useState(false);
    const [datas, setDatas] = useState([] as any);

    const getPayments = async () => {
        setLoader(true)
        try {
            await ApiServices('get', ApiConfig.ALL_PURCHASE_POINT).then((res: any) => {
                let tmp = res?.data?.data
                console.log(tmp, "Point History response..")
                setLoader(false)
                if (tmp) {
                    setDatas(tmp)
                }
            })
        } catch (error) {
            setLoader(false)
            console.log(error)
        }
    }

    useEffect(() => {
        getPayments()
    }, [])

    const chartData = useMemo(() => {
        const dataByUserDay = {};
        const userNames = {}; // Store user names

        // Process data
        datas.forEach(curr => {
            const day = dayjs(curr.createdAt).format("YYYY-MM-DD");
            const userId = curr.user_id;
            const name = curr.User.firstname;

            if (!dataByUserDay[userId]) {
                dataByUserDay[userId] = {};
            }

            if (!dataByUserDay[userId][day]) {
                dataByUserDay[userId][day] = 0;
            }

            dataByUserDay[userId][day] += curr.point; // Summing points per day for each user

            // Map user names to IDs
            if (!userNames[userId]) {
                userNames[userId] = name;
            }
        });

        const labels = [...new Set(
            datas.map(item => dayjs(item.createdAt).format("YYYY-MM-DD"))
        )].sort(); // X-axis: Day labels sorted

        const datasets = Object.keys(dataByUserDay).map(userId => {
            const points = labels.map(day => dataByUserDay[userId][day] || 0); // Y-axis: Total points per day for each user

            return {
                label: `${userNames[userId]}`, // Use stored user names
                data: points,
                fill: false,
                borderColor: `hsl(${Math.random() * 360}, 70%, 50%)`, // Random color for each user
                tension: 0.4,
            };
        });

        return {
            labels,
            datasets,
        };
    }, [datas]);

    const options = {
        responsive: true,
        plugins: {
            tooltip: {
                mode: "index",
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: "Per Day Users Points",
                },
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 10,
                },
            },
            y: {
                title: {
                    display: true,
                    text: "Total Points",
                },
            },
        },
    };


    return (
        <div className="pt-4">
            <h5>All User Point History:</h5>
            <Line data={chartData} options={options} />
        </div>
    );
};

export default PointHistoryChart;


