const BaseUrl = "https://ommm.in/sadhana-api"
// const BaseUrl = "http://localhost:8080"

export const ApiConfig = {
    URL: BaseUrl,
    LOGIN: BaseUrl + "/api/login",
    BANNERS: BaseUrl + "/api/Adminbanners",
    UPLOAD: BaseUrl + "/api/media",
    CREATE_BANNER: BaseUrl + "/api/create/banner",
    DELETE_BANNER: BaseUrl + "/api/banner",
    ALL_USERS: BaseUrl + "/api/users",
    CATEGORIES: BaseUrl + "/api/categorie",
    ADMIN_CATEGORIES: BaseUrl + "/api/AdminCategorie",
    BLOCK_USER: BaseUrl + "/api/userBlock",
    PLAY_LIST: BaseUrl + "/api/playlists",
    GET_PLAYLIST: BaseUrl + "/api/playlist/",
    PLAYLIST: BaseUrl + "/api/AdminPlaylists/",
    PLAYLIST_ADD_CATEGORIES: BaseUrl + "/api/playlistAddCategories",
    PLAYLIST_Delete_CATEGORIES: BaseUrl + "/api/deletePlaylistCategories",
    PLAYLISTS_IDS: BaseUrl + "/api/getAryPlaylist",
    PLAYLISTS_IDS: BaseUrl + "/api/getAryPlaylist",
    ADMIN_ITEM: BaseUrl + "/api/AdminItemUpdate",
    PAYMENT: BaseUrl + "/api/payments",
    APP_CONFIG: BaseUrl + "/api/appConfig",
    ALL_PURCHASE_POINT: BaseUrl + "/api/getAllPurchasePointAdmin",
    SHORT_CATEGORIES: BaseUrl + "/api/CategorisSort",
    SEND_ADMIN_MESSAGE: BaseUrl + "/api/sendMessageAdmin",

};