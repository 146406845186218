import React, { useEffect, useState } from 'react'
import HeaderCmp from '../../components/HeaderCmp';
import SliderCmp from '../../components/SliderCmp';
import DataTable from './DataTable';
import { ApiServices } from '../../services/apiServices';
import { ApiConfig } from '../../services/ApiConfigs';

const Payment = () => {
    const [loader, setLoader] = useState(false);
    const [datas, setDatas] = useState([] as any);

    const getPayments = async () => {
        setLoader(true)
        try {
            await ApiServices('get', ApiConfig.PAYMENT).then((res: any) => {
                let tmp = res?.data?.data
                console.log(tmp, "PAYMENT response..")
                setLoader(false)
                if (tmp) {
                    setDatas(tmp)
                }
            })
        } catch (error) {
            setLoader(false)
            console.log(error)
        }
    }

    useEffect(() => {
        getPayments()
    }, [])

    return (
        <div>
            <HeaderCmp />
            <div className='d-flex'>
                <SliderCmp />
                <div className='p-5 w-100 '>
                    <div className='d-flex' style={{ backgroundColor: "#282A3A", padding: 15, borderRadius: 10, paddingTop: 20, }}>
                        <h5 style={{ color: "white", paddingLeft: 15 }}>Payment</h5>
                    </div>
                    <div style={{ padding: 10 }}>
                        <DataTable data={datas} loader={loader} />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Payment