
import React, { useState } from 'react';
import { Space, Table, Tag, Popconfirm, Image, Input, Row, Col } from 'antd';
import { AudioOutlined, MessageOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Cascader } from 'antd';
import { Button, } from 'antd';
import { ApiConfig } from '../../services/ApiConfigs';
import { ApiServices } from '../../services/apiServices';
import Search from 'antd/es/input/Search';
import MessageCmp from './MessageCmp';

const UserDataTable = ({ data, deleteUser, getUsers, loading, setData }) => {
    // const user = useSelector((state) => state.UserData.userData.data)
    const [selectedValue, setSelectedValue] = useState(['0']);
    const [selectedUser, setSelectedUser] = useState([]);
    const [value, setValue] = useState('');
    const options = [
        { value: '0', label: 'Active' },
        { value: '1', label: 'Blocked' }
    ]

    const onChange = async (status, value) => {
        setSelectedValue(status[0])
        const body = {
            "userId": value.id,
            "status": status[0]
        }
        try {
            await ApiServices('post', ApiConfig.BLOCK_USER, body).then((res) => {
                console.log(res, "block by user")
                getUsers()
            })
        } catch (error) {
            console.log(error)
        }
    };

    const [sendMessageModal, setSendMessageModal] = useState(false);
    const [messageType, setMessageType] = useState("");

    const columns = [
        {
            title: 'Image',
            dataIndex: 'UploadMedium',
            key: 'UploadMedium',
            render: (image) => {
                return <Image
                    src={image?.url ? image?.url : "./image/logo.png"}
                    width={60}
                    height={60}
                />
            }
        },
        {
            title: 'Name',
            dataIndex: 'firstname',
            key: 'firstname',
            render: (text, _) => <a>{text + " " + _.lastname}</a>,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'role',
            dataIndex: 'role',
            key: 'role',
            render: (_, { role }) => {
                var color = ""
                var str = ""
                if (role == 1) {
                    color = "pink"
                    str = "Teacher"
                } else if (role == 0) {
                    color = "geekblue"
                    str = "User"
                } else if (role == 2) {
                    color = "green"
                    str = "Admin"
                }
                return (
                    <>
                        <Tag color={color}>
                            {str}
                        </Tag>
                    </>
                )
            }
        },

        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Space size="middle">
                        {/* <Popconfirm
                            title="Delete the task"
                            description="Are you sure to delete this task?"
                            icon={
                                <QuestionCircleOutlined
                                    style={{
                                        color: 'red',
                                    }}
                                />
                            }
                            onConfirm={() => deleteUser(record.id)}
                        >
                            <img src='./image/delete.png' className='img-fluid shadow-4' alt='...' style={{ height: 20, width: 20, }} />
                        </Popconfirm> */}
                        <img
                            onClick={() => {
                                setMessageType(1)
                                setSendMessageModal(true)
                                setSelectedUser(record)
                            }
                            } src='./image/comments.png' className='img-fluid shadow-4' alt='...' style={{ height: 20, width: 20, }} />
                    </Space >
                    <Cascader
                        value={record.status == 0 ? "Active" : "Blocked"}
                        defaultValue={selectedValue}
                        options={options}
                        onChange={(value) => onChange(value, record)}
                        placeholder="Please select" />
                </Space >
            ),
        },
    ];

    const [searchData, setSearchData] = useState([]);


    return (
        <>
            <MessageCmp isModalOpen={sendMessageModal} setIsModalOpen={setSendMessageModal} type={messageType} selectedUser={selectedUser} />

            <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Col span={6}>
                    <div className='py-3'>
                        <Button onClick={() => {
                            setMessageType(0)
                            setSendMessageModal(true)
                        }} type="primary" icon={<img src='./image/messages.png' className='img-fluid shadow-4' alt='...' style={{ height: 20, width: 20, }} />} iconPosition="start" >
                            Broadcast message
                        </Button>
                    </div>
                </Col>
                <Col>
                    <div className='py-3'>
                        <Search
                            placeholder="Search teacher"
                            enterButton
                            onChange={e => {
                                const currValue = e.target.value.toLowerCase(); // Convert input to lowercase
                                setValue(currValue);
                                const filteredData = data.filter(entry =>
                                    entry.firstname.toLowerCase().includes(currValue) || entry.lastname.toLowerCase().includes(currValue)
                                );
                                if (currValue) {
                                    setSearchData(filteredData);
                                } else {
                                    setSearchData([]);
                                }
                            }}
                        />
                    </div>
                </Col>
            </Row>
            <Table columns={columns} dataSource={value ? searchData : data} loading={loading} />
        </>
    )
}

export default UserDataTable