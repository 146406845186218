import { useEffect, useState } from 'react';
import HeaderCmp from '../../components/HeaderCmp';
import SliderCmp from '../../components/SliderCmp';
import { useLocation, useNavigate } from 'react-router-dom';
import { Image, Popconfirm, Space, Table, Tag } from 'antd';
import SwitchCmp from '../../components/SwitchCmp';
import { EyeOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { successMsg } from '../../utils/ShowMessage';
import { ApiServices } from '../../services/apiServices';
import { ApiConfig } from '../../services/ApiConfigs';
import ViewFileCmp from './ViewFileCmp';

const MediaView = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [data, setData] = useState([] as any)
    const [loader, setLoader] = useState(false)
    const [clickData, setClickData] = useState([] as any)
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        getPlayListById(location?.state?.id)
    }, [location?.state?.id])

    const onChangeMedia = async (e: any, resData: any) => {
        console.log(e, "click on change..")
        try {
            await ApiServices('put', ApiConfig.ADMIN_ITEM + '/' + resData.UploadMedium.id, { status: e ? 1 : 0 }).then((res: { status: number; }) => {
                console.log(res, "update categories response..");
                if (res.status == 200) {
                    successMsg("Update successfully.");
                    getPlayListById(data.id)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    const getPlayListById = async (ID: Number) => {
        try {
            setLoader(true)
            await ApiServices('GET', ApiConfig.GET_PLAYLIST + ID).then((res: any) => {
                if (res.status == 200) {
                    console.log(res, "get categories response..");
                    setData(res?.data?.data[0] || [])
                    setLoader(false)
                }
            })
        } catch (error) {
            setLoader(false)
            console.log(error)
        }
    }

    const columns = [
        {
            title: 'Image',
            dataIndex: 'thumbnailMedia',
            key: 'thumbnailMedia',
            render: (image: any) => {
                return <Image
                    src={image?.url ? image?.url : "./image/logo.png"}
                    width={60}
                    height={60}
                />
            }
        },
        {
            title: 'Name',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Teacher Name',
            dataIndex: 'User',
            key: 'User',
            render: (record: any) => (
                <p>{record?.city} {record?.lastname}</p>
            )
        },
        {
            title: 'Type',
            dataIndex: 'UploadMedium',
            key: 'UploadMedium',
            render: (record) => (
                <Tag color="magenta">{record.type.split('/').pop()}</Tag>
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Space size="middle">
                        <EyeOutlined onClick={() => {
                            console.log(record);
                            setIsModalOpen(true)
                            setClickData(record)
                        }} />
                        <SwitchCmp
                            onChange={onChangeMedia}
                            value={record.UploadMedium.status}
                            data={record}
                        />
                        <Popconfirm
                            title="Delete the task"
                            description="Are you sure to delete this task?"
                            icon={
                                <QuestionCircleOutlined
                                    style={{
                                        color: 'red',
                                    }}
                                />
                            }
                            onConfirm={() => console.log(record.id)}
                        >
                            <img src='./image/delete.png' className='img-fluid shadow-4' alt='...' style={{ height: 20, width: 20, }} />
                        </Popconfirm>
                    </Space >
                </Space >
            ),
        }
    ];

    return (
        <div>
            <HeaderCmp />
            <div className='d-flex'>
                <SliderCmp />
                <div className='p-5 w-100'>
                    <div className='d-flex' style={{ backgroundColor: "#282A3A", padding: 15, borderRadius: 10, paddingTop: 20, alignItems: 'center', justifyContent: 'space-between' }}>
                        <img onClick={() => navigate(-1)} src='./image/back-button.png' className='img-fluid shadow-4' alt='...' style={{ height: 40, width: 40, }} />
                        <h4 style={{ color: "white" }}>{data.title || ""}</h4>
                        <div />
                    </div>
                    <div className='p-4'>
                        <Table dataSource={data?.PlaylistSongs || []} columns={columns} loading={loader} />
                    </div>
                </div>
            </div>

            {/* modal view files */}

            <ViewFileCmp data={clickData} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
        </div>
    )
}

export default MediaView