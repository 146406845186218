import React, { useState } from 'react';
import { Button, Form, Input, Select, Space, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { ApiConfig } from '../../services/ApiConfigs';
import { ApiServices } from '../../services/apiServices';

const { Option } = Select;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

const MessageCmp = ({ isModalOpen, setIsModalOpen, type, selectedUser }) => {

    // type 1 = single // user 0 = all user

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [form] = Form.useForm();

    const onFinish = (values) => {
        if (selectedUser?.id) {
            values.user_id = selectedUser?.id
        }
        values.type = type
        console.log(values);
        try {
            ApiServices('post', ApiConfig.SEND_ADMIN_MESSAGE, values).then((res) => {
                console.log(res, "SEND_ADMIN_MESSAGE response..");
                if (res.status) {
                    setIsModalOpen(false)
                }
            })
        } catch (error) {
            console.log(error)
        }
    };

    const onReset = () => {
        form.resetFields();
    };

    const onFill = () => {
        form.setFieldsValue({ note: 'Hello world!', gender: 'male' });
    };

    return (
        <>
            <Modal title={type ? "Send Notification" : "Broadcast Notification"} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
            >
                <Form
                    {...layout}
                    form={form}
                    name="control-hooks"
                    onFinish={onFinish}
                    layout='vertical'
                    style={{ width: "65vh" }}
                >
                    <Form.Item name="title" label="Title" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="body" label="Body" rules={[{ required: true }]}>
                        <TextArea
                            // value={value}
                            // onChange={(e) => setValue(e.target.value)}
                            placeholder="Controlled autosize"
                            autoSize={{ minRows: 3, maxRows: 5 }}
                        />
                    </Form.Item>

                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => prevValues.gender !== currentValues.gender}
                    >
                        {({ getFieldValue }) =>
                            getFieldValue('gender') === 'other' ? (
                                <Form.Item name="customizeGender" label="Customize Gender" rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                            ) : null
                        }
                    </Form.Item>
                    <Form.Item {...tailLayout} style={{ width: '86vh' }}>
                        <Space>
                            <Button htmlType="button" onClick={onReset}>
                                Reset
                            </Button>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default MessageCmp;