import React, { useEffect, useState } from 'react';
import { Button, Col, Image, Input, Modal, Cascader, Radio, Tag } from 'antd';
import { ApiServices } from '../../services/apiServices';
import { ApiConfig } from '../../services/ApiConfigs';
import SwitchCmp from '../../components/SwitchCmp';

const SelectCategories = ({ isCatModalOpen, setIsCatModalOpen, getCategories, selectCatId }) => {

    const [selectDropPlaylist, setSelectDropPlaylist] = useState([]);
    const [selectPlaylistId, setSelectPlaylistId] = useState([]);

    const [playlistData, setPlaylistData] = useState([]);

    const [choosePlaylist, setChoosePlaylist] = useState([]);


    useEffect(() => {
        console.log(selectCatId, ":selectCatId");
        setChoosePlaylist([])
        selectCatId?.Playlists?.map((res) => {
            playlistData?.map((pres) => {
                if (res == pres.id) {
                    const body = [{
                        id: pres.id,
                        title: pres.title,
                        catId: selectCatId.id
                    }]
                    setChoosePlaylist((p) => [...p, ...body])
                }
            })
        })
    }, [selectCatId])



    const playlistAddCategories = () => {
        console.log(selectCatId.id, selectPlaylistId.flat());
        try {
            ApiServices('post', ApiConfig.PLAYLIST_ADD_CATEGORIES, { categories_id: selectCatId.id, Playlists: selectPlaylistId.flat() }).then((res: any) => {
                let tmp = res
                console.log(tmp, "Edit PLAY_LIST response..");
                getPlaylists()
                getCategories()
            })
        } catch (error) {
            console.log(error)
        }
    }

    const handleOk = () => {
        setIsCatModalOpen(false);
        playlistAddCategories()
    };

    const handleCancel = () => {
        setIsCatModalOpen(false);
    };

    const onChange = (value) => {
        console.log(value, "playlist id...");
        setSelectPlaylistId(value)
    };

    useEffect(() => {
        getPlaylists()
    }, [])

    const getPlaylists = async () => {
        try {
            await ApiServices('get', ApiConfig.PLAY_LIST).then((res: any) => {
                let tmp = res?.data?.data
                setPlaylistData(tmp)
                console.log(tmp, "PLAY_LIST response..");
                var CascadeOptions: any = []
                tmp.map((res) => {
                    CascadeOptions.push({ value: res.id, label: res.title, })
                })
                if (tmp) {
                    setSelectDropPlaylist(CascadeOptions)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    const deleteChooseCat = (val: any) => {
        console.log(val);

        try {
            ApiServices('post', ApiConfig.PLAYLIST_Delete_CATEGORIES, { id: val.id, catId: val.catId }).then((res) => {

                console.log(res, "delete response..");
                setIsCatModalOpen(false)
                getPlaylists()
                getCategories()
            })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Modal
                width={600}
                title={"Select Categories:"} open={isCatModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div className='p-3'>
                    <div className='pt-3 w-[100rem]'>

                        <Cascader
                            style={{
                                width: "100%"
                            }}

                            multiple
                            options={selectDropPlaylist}
                            dropdownMenuColumnStyle={{
                                width: "100%"
                            }}
                            onChange={onChange} placeholder="Please select Categories" />
                        {
                            choosePlaylist?.map((res: any) => {
                                return (

                                    <span className='pt-3' style={{ display: 'inline-block' }}>
                                        <Tag
                                            closable
                                            onClose={(e) => {
                                                e.preventDefault();
                                                deleteChooseCat(res)
                                            }}
                                        >
                                            {res.title}
                                        </Tag>
                                    </span>
                                )
                            })
                        }

                    </div>
                </div>
            </Modal>
        </>
    );
};

export default SelectCategories;