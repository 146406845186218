export const routes = {
    LOGIN: "/",
    DASHBOARD: "/Dashboard",
    BANNER: "/Banner",
    USER: "/User",
    CATEGORIES: "/Categories",
    SETTINGS: "/Setting",
    APP: "/App",
    PLAYLIST: "/Playlist",
    MEDIA_VIEW: "/MediaView",
    PAYMENT: "/Payment",
}  