import { ActionTypes } from "../constants/action-types";

export const setUserData = (userData) => {
  return {
    type: ActionTypes.SET_USER_DATA,
    payload: userData,
  };
};
export const userLogout = (logout) => {
  return {
    type: ActionTypes.LOGOUT,
    payload: logout,
  };
};

