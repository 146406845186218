import React, { useEffect, useRef, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import styles from "./style.module.css";
import Nav from 'react-bootstrap/Nav';
import HeaderCmp from '../../components/HeaderCmp';
import SliderCmp from '../../components/SliderCmp';
import { Col, Collapse, Row, Tabs } from 'antd';
import { ApiServices } from '../../services/apiServices';
import { ApiConfig } from '../../services/ApiConfigs';

const MobileApp = () => {

  const [tabMenu, setTabMenu] = useState([])

  const getCategoriesCalled = useRef(false); // Ref to track if `getCategories` has been called

  useEffect(() => {
    if (!getCategoriesCalled.current) {
      getCategories();
      getPlaylists()
      getCategoriesCalled.current = true;
    }
  }, []);

  const getPlaylists = async (ids) => {

    try {
      const res = await ApiServices('post', ApiConfig.PLAYLISTS_IDS, { ids });
      console.log(res, "Playlist response..");
      return res.data.data || [];
    } catch (error) {
      console.error('Error fetching playlists:', error);
      throw error;
    }
  };

  const getCategories = async () => {
    console.log("call")
    try {
      await ApiServices('get', ApiConfig.CATEGORIES).then((res) => {
        let tmp = res?.data?.data
        setTabMenu([]);
        tmp.map(async (res, index) => {
          // await getPlaylists(res.Playlists)
          const playlists = await getPlaylists(res.Playlists);

          const body = {
            label: res.title,
            key: index,
            children: RenderTab(playlists),
          }
          console.log(body)
          // res.title
          setTabMenu((old) => [...old, body])
        })
        console.log(tmp, "ALL_CATEGORIES response..");
      })
    } catch (error) {
      console.log(error)
    }
  }
  const RenderTab = (playlists) => {
    console.log(playlists, "Playlist for category:");

    const renderSingleItem = (playlist) => {
      return (
        <div className='w-[50%]'>
          <Row gutter={30}>
            <Col span={8}>
              <div className="block">8 columns</div>
            </Col>
          </Row>
        </div>
      )
    }

    const items = playlists.map((playlist, index) => ({
      key: index.toString(),
      label: playlist.title,
      children: renderSingleItem(playlist),
    }));



    return (
      <Collapse accordion items={items} />
    )
  }

  return (
    <div>
      <HeaderCmp />
      <div className='d-flex'>
        <SliderCmp />
        <div className='p-5 w-100 '>
          <div className='d-flex' style={{ backgroundColor: "#282A3A", padding: 15, borderRadius: 10, paddingTop: 20, }}>
            <h5 style={{ color: "white", paddingLeft: 15 }}>Mobile App</h5>
          </div>

          <div className='p-3 w-[50%]'>
            <Tabs
              tabBarStyle={{
                display: 'flex',
                width: " 50%",
                justifyContent: 'space-between'
              }}
              size="large"
              defaultActiveKey="1"
              items={tabMenu}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileApp