import React, { useEffect } from 'react';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';
import { NavLink } from 'react-router-dom';
import { routes } from '../utils/routes.config';
import { Tag } from 'antd';
import { useSelector } from 'react-redux';

const SliderCmp = () => {
  const userDetail = useSelector((state) => state.UserData.userData.data);
  return (
    <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}>
      <CDBSidebar textColor="#fff"
        backgroundColor="#333"
      >

        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>} style={{ alignItems: 'center', textAlign: "center" }} >
          <div style={{ alignItems: 'center' }}>
            <a href="/" className="text-decoration-none" style={{ color: 'white', backgroundColor: 'green' }}>
              <img src='./image/logo.png' className='img-fluid shadow-4' alt='...' style={{ height: 50, width: 50, }} />
            </a>
          </div>
          {
            userDetail?.role == 1 ?
              <Tag color="geekblue">Teacher</Tag> :
              <Tag color="magenta">Admin</Tag>
          }

        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
            <div className={window.location.pathname == '/Dashboard'}>
              <NavLink exact to="/Dashboard" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
              </NavLink>
            </div>

            {
              userDetail?.role == "2" ?
                <>
                  <div className={window.location.pathname == '/Banner'}>
                    <NavLink exact to="/Banner" activeClassName="activeClicked">
                      <CDBSidebarMenuItem icon="table">Banner</CDBSidebarMenuItem>
                    </NavLink>
                  </div>

                  <div className={window.location.pathname == '/Categories'}>
                    <NavLink exact to="/Categories" activeClassName="activeClicked">
                      <CDBSidebarMenuItem icon="chart-line">Categories</CDBSidebarMenuItem>
                    </NavLink>
                  </div>

                  <div className={window.location.pathname == routes.PAYMENT}>
                    <NavLink exact to={routes.PAYMENT} activeClassName="activeClicked">
                      <CDBSidebarMenuItem icon="rupee-sign">Payment</CDBSidebarMenuItem>
                    </NavLink>
                  </div>
                </> : <></>
            }

            <div className={window.location.pathname == routes.PLAYLIST}>
              <NavLink exact to={routes.PLAYLIST} activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="music">Playlist</CDBSidebarMenuItem>
              </NavLink>
            </div>

            {/* {
              userDetail?.role == "2" ?
                <div className={window.location.pathname == '/Categories'}>
                  <NavLink exact to={routes.APP} activeClassName="activeClicked">
                    <CDBSidebarMenuItem icon="mobile">Mobile-App</CDBSidebarMenuItem>
                  </NavLink>
                </div> : <></>
            } */}
            {
              userDetail?.role == "2" ?
                <>
                  <div className={window.location.pathname == '/User'}>
                    <NavLink exact to="/User" activeClassName="activeClicked">
                      <CDBSidebarMenuItem icon="user">User </CDBSidebarMenuItem>
                    </NavLink>
                  </div>
                  <div className={window.location.pathname == '/Setting'}>
                    <NavLink exact to="/Setting" activeClassName="activeClicked">
                      <CDBSidebarMenuItem icon="cog">Setting</CDBSidebarMenuItem>
                    </NavLink>
                  </div>
                </>
                : <></>
            }

          </CDBSidebarMenu>
        </CDBSidebarContent>

        <CDBSidebarFooter style={{ textAlign: 'center' }}>
          <div
            style={{
              padding: '20px 5px',
            }}
          >
            Sidebar Footer
          </div>
        </CDBSidebarFooter>
      </CDBSidebar>
    </div >
  );
};

export default SliderCmp;