import React, { useEffect, useState } from 'react';
import style from "./style.module.css";
import { useNavigate } from 'react-router-dom';
import { ApiServices } from '../../services/apiServices';
import { ApiConfig } from '../../services/ApiConfigs';
import { useDispatch } from 'react-redux';
import { setUserData } from '../../redux/actions/DataActions';
import { handleCheckAuth } from "../../Utils/handleAuth";
import { errorMsg, successMsg } from '../../utils/ShowMessage';
import { routes } from "../../Utils/routes.config";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "admin@admin.com",
    password: "admin@123"
  });
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  useEffect(() => {
    const authUser = handleCheckAuth()
    if (authUser) {
      navigate(routes.DASHBOARD)
    }
  }, [])

  useEffect(() => {
    const labels = document.querySelectorAll('.form-control label');
    labels.forEach((label) => {
      label.innerHTML = label.innerText
        .split('')
        .map((letter, index) => `<span style="transition-delay:${index * 40}ms">${letter}</span>`)
        .join('');
    });
  }, []);

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};
    // Validate email
    if (!formData.email) {
      newErrors.email = "Email is required";
      isValid = false;
    }
    else if (!formData.email.match(/\S+@\S+\.\S+/)) {
      newErrors.email = "Please enter a valid Email id"
      isValid = false;
    }
    // Validate password
    if (!formData.password) {
      newErrors.password = "Password is required";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        await ApiServices('post', ApiConfig.LOGIN, formData).then((res) => {
          console.log(res, "login response..")
          if (res.status == 200) {
            // 1 = teacher 2= admin role 
            if (res.data.role == "1" || res.data.role == "2") {
              dispatch(setUserData(res));
              successMsg("Login successfully.")
              navigate('/Dashboard');
            } else {
              errorMsg("User not authorized. Please contact admin.")
            }
          } else {
            errorMsg(res.msg.toUpperCase())
          }
        })
      } catch (error) {
        console.log(error)
        errorMsg("Some thing went wrong.")
      }
      setSubmitted(true);
    } else {
      errorMsg("Some thing went wrong.")
      console.log("error")
    }
  };

  return (
    <div className={style.body}>
      <div className={style.container}>

        <div style={{ textAlign: 'center' }}>

          <img src='./image/logo.png' className='img-fluid shadow-4' alt='...' />
        </div>
        {/* <h1>Login</h1> */}
        <form
          onSubmit={handleSubmit} >
          <div className="form-control">
            <input type="text" required name="email" value={formData.email}
              onChange={handleInputChange} />
            <label >Email</label>
            {errors.email && <div className={style.error} >{errors.email}</div>}
          </div>
          <div className="form-control">
            <input type="password" required name="password"
              value={formData.password}
              onChange={handleInputChange}
            />
            <label>Password</label>
            {errors.password && <div className={style.error}>{errors.password}</div>}
          </div>
          <button className={style.btn} onClick={handleSubmit} >Login</button>
        </form>
      </div>
    </div>
  );
}

export default Login

