import { Button, Divider, InputNumber } from "antd"
import { ApiServices } from '../../services/apiServices';
import { ApiConfig } from '../../services/ApiConfigs';
import { useEffect, useState } from "react";
import { successMsg } from '../../utils/ShowMessage';

const PriceToPont = () => {

    const [point, setPoint] = useState(0 as Number)
    const [minutePoint, setMinutePoint] = useState(0 as Number)

    const onChange: any = (value) => {
        console.log('changed', value);
        setPoint(value)
    };
    const onChangeminuts: any = (value) => {
        setMinutePoint(value)
    };

    const handlePoint: any = async (key: any, value: any) => {
        const body = {
            "Key": key,
            "value": value
        }
        try {
            await ApiServices('post', ApiConfig.APP_CONFIG, body).then((res: any) => {
                console.log(res, "App config response..");
                successMsg("Success point update.")
                getPoints()
            })
        } catch (error) {
            console.log(error)
        }
    };

    const getPoints: any = async () => {
        try {
            await ApiServices('get', ApiConfig.APP_CONFIG).then((res: any) => {
                console.log(res, "get point response..");
                let tmp = res?.data?.data.filter((res) => res.Key == 'per_point_Price')
                let per_minute_Point_Price = res?.data?.data.filter((res) => res.Key == 'per_minute_Point_Price')
                console.log(tmp)
                setPoint(JSON.parse(tmp?.[0].value))
                setMinutePoint(JSON.parse(per_minute_Point_Price?.[0].value))
            })
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        getPoints()
    }, [])

    return (
        <>
            <Divider style={{ borderColor: '#7cb305' }}>Point set:</Divider>
            <p className="pb-1">* Indian rupees are set at a rate of one point.</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{ fontWeight: "bold", margin: 5 }}>₹1 x : </p>
                <InputNumber style={{
                    margin: 10
                }} value={point} onChange={onChange} />
                <Button className="ml-2" type="primary" onClick={() => handlePoint('per_point_Price', point)}>Submit</Button>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{ fontWeight: "bold", margin: 5 }}>1 Minute point price : </p>
                <InputNumber style={{
                    margin: 10
                }} value={minutePoint} onChange={onChangeminuts} />
                <Button className="ml-2" type="primary" onClick={() => handlePoint('per_minute_Point_Price', minutePoint)}>Submit</Button>
            </div>
        </>
    )
}

export default PriceToPont