import React, { useState, useEffect } from 'react'
import HeaderCmp from '../../components/HeaderCmp';
import SliderCmp from '../../components/SliderCmp';

import Modal from 'react-bootstrap/Modal';
import style from "./style.module.css";
import CatagoriesDataTable from './CategoriesDataTable';
import { useNavigate } from 'react-router-dom';
import { Checkbox, Form, Input, Upload, Button } from 'antd';
import { ApiServices, uploadDocument } from '../../services/apiServices';
import { ApiConfig } from '../../services/ApiConfigs';
import { InboxOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import SelectCategories from './SelectCategories';
import { successMsg } from '../../utils/ShowMessage';

const Categories = () => {
    const [form] = Form.useForm();
    const userDetail = useSelector((res: any) => res.UserData.userData.data)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCatModalOpen, setIsCatModalOpen] = useState(false);
    const [selectCatId, setSelectCalId] = useState('');
    const [loader, setLoader] = useState(false);

    const { Dragger } = Upload;

    const props = {
        name: 'file',
        multiple: true,
        action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
        onChange(info: { file: React.SetStateAction<never[]>; fileList: any; }) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, "file");
                console.log(info.fileList, "file list");
                setImgUrl(info.file)
            }
            // if (status === 'done') {
            //     message.success(`${info.file.name} file uploaded successfully.`);
            // } else if (status === 'error') {
            //     message.error(`${info.file.name} file upload failed.`);
            // }
        },
        onDrop(e: { dataTransfer: { files: any; }; }) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const [show, setShow] = useState(false);
    const { TextArea } = Input;
    const [imgUrl, setImgUrl] = useState([] as any);
    const [flagFlow, setFlagFlow] = useState("add");

    const [categoriesData, setCategoriesData] = useState([])
    const [editCategoriesData, setEditCategoriesData] = useState([] as any)

    const onFinish = async (values: { title: any; }) => {
        console.log('Success:', values);
        setLoader(true)
        if (flagFlow == "add") {
            uploadFile(values)
        } else {
            // uploadCategories(editCategoriesData.UploadMedium.id, values);

            console.log(imgUrl.originFileObj, "kj")

            if (imgUrl.originFileObj) {
                try {
                    const uploadRes = await uploadDocument(imgUrl.originFileObj, "upload document");
                    console.log(uploadRes, "upload..")
                    setShow(false)
                    // uploadCategories(uploadRes.data.data.id, values)
                    const body = {
                        user_id: userDetail.id,
                        media_id: uploadRes.data.data.id,
                        title: values.title,
                        discriptions: ""
                    };
                    console.log(body)
                    await ApiServices('put', ApiConfig.CATEGORIES + "/" + editCategoriesData.id, body).then((res: any) => {
                        console.log(res, "upload Categories response..")
                        getCategories()
                        setShow(false)
                    })
                } catch (error) {
                    console.log(error);
                }
            } else {
                const body = {
                    user_id: userDetail.id,
                    media_id: editCategoriesData.UploadMedium.id,
                    title: values.title,
                    discriptions: ""
                };
                console.log(body)
                await ApiServices('put', ApiConfig.CATEGORIES + "/" + editCategoriesData.id, body).then((res: any) => {
                    console.log(res, "upload Categories response..")
                    getCategories()
                    setShow(false)
                })
            }
        }
    };

    const getCategories = async () => {
        setLoader(true)
        try {
            await ApiServices('get', ApiConfig.ADMIN_CATEGORIES).then((res: any) => {
                let tmp = res?.data?.data
                console.log(tmp, "ALL_CATEGORIES response..")
                setLoader(false)
                if (tmp) {
                    setCategoriesData(tmp.sort((a: any, b: any) => a.sort - b.sort))
                }
            })
        } catch (error) {
            setLoader(false)
            console.log(error)
        }
    }

    //file-upload
    const uploadFile = async (values: any) => {
        try {
            const uploadRes = await uploadDocument(imgUrl.originFileObj, "upload document");
            console.log(uploadRes, "upload..")
            setShow(false)
            setLoader(false)
            uploadCategories(uploadRes.data.data.id, values)
        } catch (error) {
            console.log(error);
        }
    };

    // file item upload 
    const uploadCategories = async (id: any, values: { title: any; }) => {
        setLoader(true)
        try {
            const body = {
                user_id: userDetail.id,
                media_id: id,
                title: values.title,
                // discriptions: values.description
                discriptions: ""
            };
            console.log(body)
            await ApiServices('post', ApiConfig.CATEGORIES, body).then((res: any) => {
                console.log(res, "upload Categories response..")
                getCategories()
            })
        } catch (error) {
            console.log(error);
        }
    }

    const deleteCategories = async (id: string) => {
        setLoader(true)
        try {
            await ApiServices('delete', ApiConfig.CATEGORIES + '/' + id).then((res: any) => {
                console.log(res, "delete categories response..")
                getCategories()
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getCategories()
    }, [])

    const editCat = () => {
        form.setFieldsValue({
            title: 'Bamboo',
        });
    }

    useEffect(() => {

        if (editCategoriesData.length != 0) {
            editCat()
            setShow(true)
        }

    }, [editCategoriesData])


    //update categories
    const updateCategories = async (id: any, data: any) => {
        try {
            await ApiServices('put', ApiConfig.ADMIN_CATEGORIES + '/' + data?.id, { status: id ? 1 : 0 }).then((res: { status: number; }) => {
                console.log(res, "update categories response..");
                if (res.status == 200) {
                    getCategories();
                    successMsg("Update successfully.")
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div>
            <HeaderCmp />
            <div className='d-flex'>
                <SliderCmp />
                <div className='p-5 w-100 '>
                    <div className='d-flex' style={{ backgroundColor: "#282A3A", padding: 15, borderRadius: 10, paddingTop: 20, justifyContent: 'space-between' }}>
                        <div>
                            <h5 style={{ color: "white", paddingLeft: 15 }}>Categories</h5>
                        </div>
                        <div>
                            <Button type="primary" onClick={() => setShow(!show)} > + ADD Categories</Button>{' '}
                            <Modal show={show}
                                size='lg' onHide={() => {
                                    setImgUrl([])
                                    setFlagFlow('add')
                                    setEditCategoriesData([])
                                    setShow(!show)
                                }}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>{flagFlow == "add" ? "Add" : "Edit"} Categories</Modal.Title>
                                </Modal.Header>

                                <div style={{ alignSelf: 'center', width: '55%', padding: 10 }}>
                                    <Dragger {...props}>
                                        <p className="ant-upload-drag-icon  ">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    </Dragger>
                                </div>

                                <Modal.Body>
                                    <Form
                                        name="basic"
                                        labelCol={{
                                            span: 8,
                                        }}
                                        wrapperCol={{
                                            span: 16,
                                        }}
                                        style={{
                                            maxWidth: '70%',
                                        }}
                                        initialValues={{
                                            title: editCategoriesData.title ? editCategoriesData.title : ""
                                        }}

                                        onFinish={onFinish}
                                        onFinishFailed={onFinishFailed}
                                        autoComplete="off"
                                    >
                                        <Form.Item
                                            label="Title"
                                            name="title"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your categories title!',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        {/* <Form.Item
                                            label="Categories Description"
                                            name="description"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your categories description!',
                                                },
                                            ]}
                                        >
                                            <TextArea rows={4} />
                                        </Form.Item> */}

                                        <Form.Item
                                            wrapperCol={{
                                                offset: 8,
                                                span: 16,
                                            }}

                                        >
                                            <Button type="primary" loading={loader} htmlType="submit">
                                                Submit
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </Modal.Body>

                            </Modal>
                        </div>
                    </div>

                    <div style={{ padding: 10, }}>
                        <CatagoriesDataTable
                            loader={loader}
                            setFlagFlow={setFlagFlow}
                            setEditCategoriesData={setEditCategoriesData}
                            data={categoriesData}
                            setData={setCategoriesData}
                            deleteCategories={deleteCategories}
                            setIsModalOpen={setIsModalOpen}
                            setIsCatModalOpen={setIsCatModalOpen}
                            setSelectCalId={setSelectCalId}
                            updateCategories={updateCategories}
                        />
                    </div>

                    <SelectCategories
                        getCategories={getCategories}
                        isCatModalOpen={isCatModalOpen}
                        setIsCatModalOpen={setIsCatModalOpen}
                        selectCatId={selectCatId}
                    />
                </div>
            </div>
        </div >
    )
}

export default Categories