import { Image, Table, Tag } from "antd";
import { render } from "react-dom";

const DataTable = ({ data, loader }: any) => {

    console.log(data, "table===")

    const columns = [
        {
            title: 'No',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id - b.id,
            render: (id, record, index) => { ++index; return index; },
            showSorterTooltip: false,
        },
        {
            title: 'User',
            dataIndex: 'User',
            key: 'User',
            render: (text) => {
                return <p>{text?.firstname} {text?.lastname}, {text?.role == 1 ? "(Teacher)" : "(user)"}</p>
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => {
                return (
                    <Tag color={text == 1 ? "green" : "red"} >
                        <p style={{ textTransform: "capitalize" }}>{text == 1 ? "Success" : "fail"}</p>
                    </Tag>
                )
            }
        },
        {
            title: 'Purchase Point',
            dataIndex: 'point',
            key: 'point',
            render: (text) => {
                return <p>{text}</p>
            }
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (text) => {
                return <p>₹{text}</p>
            }
        }
    ];

    return (
        <Table dataSource={data} columns={columns} loading={loader} />
    )
}

export default DataTable



