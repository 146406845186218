import React, { useEffect, useState } from 'react'
import HeaderCmp from '../../components/HeaderCmp';
import SliderCmp from '../../components/SliderCmp';
import UserDataTable from './UserDataTable';
import { ApiServices } from '../../services/apiServices';
import { ApiConfig } from '../../services/ApiConfigs';

const User = () => {

    const [userData, setUserData] = useState([])
    const [loader, setLoader] = useState(false)
    const getUsers = async () => {
        try {
            setLoader(true)
            await ApiServices('get', ApiConfig.ALL_USERS).then((res) => {
                let tmp = res?.data?.data
                setLoader(false)
                console.log(tmp, "users response..")
                if (res.status == 200) {
                    setUserData(tmp)
                } else {
                    setUserData([])
                }
            })
        } catch (error) {
            setLoader(false)
            console.log(error)
        }
    }

    useEffect(() => {
        getUsers()
    }, [])

    const deleteUser = async (id) => {
        console.log(id, ApiConfig.ALL_USERS)
        try {
            setLoader(true)
            await ApiServices('delete', ApiConfig.ALL_USERS + '/' + id).then((res) => {
                console.log(res, "delete users response..")
                getUsers()
            })
        } catch (error) {
            setLoader(false)
            console.log(error)
        }
    }

    return (
        <div>
            <HeaderCmp />
            <div className='d-flex'>
                <SliderCmp />
                <div className='p-5 w-100 '>
                    <div className='d-flex' style={{ backgroundColor: "#282A3A", padding: 15, borderRadius: 10, paddingTop: 20, }}>
                        <h5 style={{ color: "white", paddingLeft: 15 }}>User</h5>
                    </div>
                    <div style={{ padding: 10 }}>
                        <UserDataTable data={userData} setData={setUserData} deleteUser={deleteUser} getUsers={getUsers} loading={loader} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default User