import React, { useContext, useEffect, useMemo, useState } from 'react';
import { HolderOutlined } from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Image, Table, Space, Tag, Popconfirm, Input, Row as RowAnt, Col } from 'antd';
import { EditOutlined, PlusCircleFilled, PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import SwitchCmp from '../../components/SwitchCmp';
import { ApiServices, uploadDocument } from '../../services/apiServices';
import { ApiConfig } from '../../services/ApiConfigs';
import Search from 'antd/es/input/Search';



const CatagoriesDataTable = ({ loader, data, deleteCategories, setEditCategoriesData, setFlagFlow, setIsCatModalOpen, setIsModalOpen, setSelectCalId, updateCategories, setData }: any) => {
    interface RowContextProps {
        setActivatorNodeRef?: (element: HTMLElement | null) => void;
        listeners?: SyntheticListenerMap;
    }

    const RowContext = React.createContext<RowContextProps>({});
    const [value, setValue] = useState('');
    const [searchData, setSearchData] = useState([]);

    const DragHandle: React.FC = () => {
        const { setActivatorNodeRef, listeners } = useContext(RowContext);
        return (
            <Button
                type="text"
                size="small"
                icon={<HolderOutlined />}
                style={{ cursor: 'move' }}
                ref={setActivatorNodeRef}
                {...listeners}
            />
        );
    };

    interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
        'data-row-key': string;
    }

    const Row: React.FC<RowProps> = (props) => {
        const {
            attributes,
            listeners,
            setNodeRef,
            setActivatorNodeRef,
            transform,
            transition,
            isDragging,
        } = useSortable({ id: props['data-row-key'] });

        const style: React.CSSProperties = {
            ...props.style,
            transform: CSS.Translate.toString(transform),
            transition,
            ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
        };

        const contextValue = useMemo<RowContextProps>(
            () => ({ setActivatorNodeRef, listeners }),
            [setActivatorNodeRef, listeners],
        );

        return (
            <RowContext.Provider value={contextValue}>
                <tr {...props} ref={setNodeRef} style={style} {...attributes} />
            </RowContext.Provider>
        );
    };

    const columns = [
        { key: 'sort', align: 'center', width: 80, render: () => <DragHandle /> },
        {
            title: 'Image',
            dataIndex: 'UploadMedium',
            key: 'UploadMedium',
            // render: (UploadMedium) => <img src={UploadMedium.url} className='img-fluid shadow-4' alt='...' style={{ height: 50, width: 50, }} />,

            render: (image) => {
                return <Image
                    src={image?.url ? image?.url : "./image/logo.png"}
                    width={60}
                    height={60}
                />
            }
        },
        {
            title: 'Categories Title',
            dataIndex: 'title',
            key: 'title',
            render: (text) => <a>{text}</a>,
        },
        // {
        //     title: 'Categories Description',
        //     dataIndex: 'discriptions',
        //     key: 'discriptions',
        //     render: (text) => <a>{text}</a>,
        // },
        {
            title: 'Categories Create',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text) => <a>{moment(text).format('MM/DD/YYYY')}</a>,
        },

        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <SwitchCmp
                        onChange={onChangeCategories}
                        value={record.status}
                        data={record}
                    />
                    <PlusCircleOutlined
                        onClick={() => {
                            setIsCatModalOpen(true)
                            setSelectCalId(record)
                        }}
                        style={{
                            color: 'blue',
                        }}
                    />
                    <EditOutlined onClick={() => {
                        console.log(record)
                        setFlagFlow('edit')
                        setEditCategoriesData(record)
                    }} />
                    <Popconfirm
                        title="Delete the task"
                        description="Are you sure to delete this task?"
                        icon={
                            <QuestionCircleOutlined
                                style={{
                                    color: 'red',
                                }}
                            />
                        }
                        onConfirm={() => deleteCategories(record.id)}
                    >
                        <img src='./image/delete.png' className='img-fluid shadow-4' alt='...' style={{ height: 20, width: 20, }} />
                    </Popconfirm>
                </Space >
            ),
        },
    ];

    const onChangeCategories = async (checked: boolean, data: any) => {
        console.log(`switch to ${checked}`);
        updateCategories(checked, data)
    };

    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {
            setData((prevState: any) => {
                const activeIndex = prevState.findIndex((record: any) => record.id === active?.id);
                const overIndex = prevState.findIndex((record: any) => record.id === over?.id);
                return arrayMove(prevState, activeIndex, overIndex);
            });
        }
    };

    useEffect(() => {
        updateShortCategories()
    }, [data])

    const updateShortCategories = async () => {
        let val = data.map((res: any) => res.id)
        if (val.length > 0) {
            try {
                await ApiServices('post', ApiConfig.SHORT_CATEGORIES, { sortValue: val }).then((res: { status: number; }) => {
                    console.log(res, "update categories response..");
                })
            } catch (error) {
                console.log(error)
            }
        }
    }

    return (
        <>
            <RowAnt style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                <Col span={6}>
                    <div className='py-3' >
                        <Search
                            className='flex justify-end'
                            placeholder="Search"
                            enterButton
                            onChange={e => {
                                const currValue = e.target.value.toLowerCase(); // Convert input to lowercase
                                setValue(currValue)
                                const filteredData = data.filter(entry =>
                                    entry.title.toLowerCase().includes(currValue) // Convert title to lowercase
                                );
                                if (currValue) {
                                    setSearchData(filteredData);
                                } else {
                                    setSearchData([]);
                                }
                            }}
                        />
                    </div>
                </Col>
            </RowAnt>
            <div>
                <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                    <SortableContext items={data.map((i: any) => i.id)} strategy={verticalListSortingStrategy}>
                        <Table
                            rowKey="id"
                            components={{ body: { row: Row } }}
                            columns={columns}
                            dataSource={value ? searchData : data}
                        />
                    </SortableContext>
                </DndContext>
            </div>
        </>
    );
};

export default CatagoriesDataTable;
