
import React, { useEffect, useState } from 'react'
import HeaderCmp from '../../components/HeaderCmp';
import SliderCmp from '../../components/SliderCmp';
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
import { InboxOutlined } from '@ant-design/icons';
import { Upload, Input, Modal, Button, Radio, Select } from 'antd';
import DataTable from './DataTable';
import { ApiServices, uploadDocument } from '../../services/apiServices';
import { ApiConfig } from '../../services/ApiConfigs';
import { useSelector } from 'react-redux';
import { successMsg } from '../../utils/ShowMessage';

const Banner = () => {
    const [imgUrl, setImgUrl] = useState([] as any)
    const { Dragger } = Upload;
    const [show, setShow] = useState(false);
    const [loader, setLoader] = useState(false);
    const [inputText, setInputText] = useState('')
    const [inputUrl, setInputUrl] = useState('')
    const userDetail = useSelector((res: any) => res.UserData.userData.data)
    const [playListData, setPlaylistData] = useState([])

    const [actionValue, setActionValue] = useState(1);
    const [selectPlaylistNumber, setSelectplaylistNumber] = useState(0 as Number);

    const onChangeAction = (e: any) => {
        console.log('radio checked', e.target.value);
        setActionValue(e.target.value);
    };

    const props = {
        name: 'file',
        multiple: true,
        action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
        onChange(info: any) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, "file");
                console.log(info.fileList, "file list");
                setImgUrl(info.file)
            }
            // if (status === 'done') {
            //     message.success(`${info.file.name} file uploaded successfully.`);
            // } else if (status === 'error') {
            //     message.error(`${info.file.name} file upload failed.`);
            // }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };
    const [bannersData, setBannerData] = useState([])

    const getBanners = async () => {
        setLoader(true)
        try {
            await ApiServices('get', ApiConfig.BANNERS).then((res: any) => {
                let tmp = res?.data?.data
                console.log(tmp, "banners response..")
                setLoader(false)
                if (res.status == 200) {
                    setBannerData(tmp)
                } else {
                    setBannerData([])
                }
            })
        } catch (error) {
            setLoader(false)
            console.log(error)
        }
    }
    //file-upload
    const uploadFile = async () => {
        setLoader(true)
        try {
            const uploadRes = await uploadDocument(imgUrl.originFileObj, "upload document");
            console.log(uploadRes, "upload..")
            uploadBanner(uploadRes?.data?.data?.id || null)
        } catch (error) {
            console.log(error);
        }
    };

    // file item upload 
    const uploadBanner = async (id: any) => {
        setLoader(true)
        try {
            const body = {
                user_id: userDetail.id,
                title: inputText,
                media_id: id,
                type: actionValue == 1 ? "link" : "id",
                value: actionValue == 1 ? inputUrl : selectPlaylistNumber
            };
            console.log(body, "check body..")
            await ApiServices('post', ApiConfig.CREATE_BANNER, body).then((res: any) => {
                console.log(res, "banners response..")
                getBanners()
                setShow(false)
                setLoader(false)
            })
        } catch (error) {
            console.log(error);
            setLoader(false)
        }
    }

    //delete banner
    const deleteBanner = async (id: any) => {
        try {
            await ApiServices('delete', ApiConfig.DELETE_BANNER + '/' + id).then((res) => {
                console.log(res, "delete banners response..")
                getBanners()
            })
        } catch (error) {
            console.log(error)
        }
    }

    //update banner
    const updateBanner = async (id: any, data: any) => {
        console.log(id, "== update banner ==", data)
        try {
            await ApiServices('put', ApiConfig.DELETE_BANNER + '/' + data?.id, { status: id }).then((res) => {
                console.log(res, "update banners response..");
                if (res.status == 200) {
                    getBanners();
                    successMsg("Update successfully.")
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getBanners()
        getPlaylists()
    }, [])


    const getPlaylists = () => {
        setLoader(true)
        try {
            ApiServices('get', ApiConfig.PLAY_LIST).then((res: any) => {
                let tmp = res?.data?.data
                console.log(tmp, "PLAY_LIST response..", userDetail.id);
                setLoader(false)
                if (res.status == 200) {
                    if (userDetail?.role == 1) {
                        const userPlaylist = tmp.filter((playlist: any) => playlist.user_id == userDetail.id)
                        setPlaylistData(userPlaylist)
                    } else {
                        setPlaylistData(tmp)
                    }

                } else {
                    setPlaylistData([])
                }
            })
        } catch (error) {
            setLoader(false)
            console.log(error)
        }
    }

    const handleClose = () => {
        setShow(false);
    };

    return (
        <div>
            <HeaderCmp />
            <div className='d-flex'>
                <SliderCmp />
                <div className='p-5 w-100 '>
                    <div className='d-flex' style={{ backgroundColor: "#282A3A", padding: 15, borderRadius: 10, paddingTop: 20, justifyContent: 'space-between' }}>
                        <div>
                            <h5 style={{ color: "white", paddingLeft: 15 }}>Banners</h5>
                        </div>
                        <div>
                            <Button type="primary" onClick={() => setShow(!show)} > + ADD BANNER</Button>{' '}
                            {/* <Modal show={show} onHide={() => setShow(false)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Upload Banner</Modal.Title>
                                </Modal.Header>
                                <div style={{ alignSelf: 'center', width: '80%', padding: 10 }}>
                                    <Dragger {...props}>
                                        <p className="ant-upload-drag-icon  ">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    </Dragger>
                                </div>
                                <div style={{ width: '80%', alignSelf: 'center', padding: 10 }}>
                                    <label>Banner Title</label>
                                    <Input placeholder="Banner title" onChange={(e) => setInputText(e.target.value)} />
                                </div>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => setShow(!show)}>
                                        Close
                                    </Button>
                                    <Button variant="primary" onClick={() => uploadFile()}>
                                        Save
                                    </Button>
                                </Modal.Footer>
                            </Modal> */}
                            <Modal
                                title="Upload Banner"
                                visible={show}
                                onCancel={handleClose}
                                footer={[
                                    <Button key="close" onClick={handleClose}>
                                        Close
                                    </Button>,
                                    <Button loading={loader} type="primary" key="save" onClick={uploadFile}>
                                        Save
                                    </Button>
                                ]}
                            >
                                <div style={{ textAlign: 'center', marginBottom: 20 }}>
                                    <Dragger {...props}>
                                        <p className="ant-upload-drag-icon  ">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    </Dragger>
                                </div>
                                <div style={{ marginBottom: 20 }}>
                                    <label>Banner Title</label>
                                    <Input
                                        placeholder="Banner title"
                                        value={inputText}
                                        onChange={(e) => setInputText(e.target.value)}
                                    />
                                </div>
                                <div style={{ marginBottom: 20 }}>
                                    <label>Action:</label>
                                    <Radio.Group onChange={onChangeAction} value={actionValue} className='p-3'>
                                        <Radio value={1}>Link</Radio>
                                        <Radio value={2}>Playlist</Radio>
                                    </Radio.Group>
                                    {
                                        actionValue == 1 ?
                                            <Input
                                                placeholder="Enter link"
                                                value={inputUrl}
                                                onChange={(e) => setInputUrl(e.target.value)}
                                            /> :
                                            <Select
                                                fieldNames={{
                                                    label: "title",
                                                    value: "id"
                                                }}
                                                style={{ width: "100%" }}
                                                onSelect={(e) => {
                                                    setSelectplaylistNumber(e)
                                                    console.log(e, "click to playlist..")
                                                }}
                                                showSearch
                                                placeholder="Select Playlist"
                                                filterOption={(input: any, option: any) =>
                                                    (option?.title ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={playListData}
                                            />
                                    }
                                </div>
                            </Modal>
                        </div>
                    </div>
                    <div style={{ padding: 10 }}>
                        <DataTable loader={loader} data={bannersData} deleteBanner={deleteBanner} updateBanner={updateBanner} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner