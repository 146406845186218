import './App.css'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './Pages/Login'
import Dashboard from './Pages/Dashboard'
import Banner from './Pages/Banner'
import User from './Pages/User'
import Playlist from './Pages/Playlist'
import MobileApp from './Pages/MobileApp'
import Categories from './Pages/Categories'
import Setting from './Pages/Setting'
import { ToastContainer } from 'react-toastify';
import AuthMiddleware from './components/AuthMiddleware';
import { routes } from './utils/routes.config';
import { useSelector } from 'react-redux';
import MediaView from './Pages/MediaView';
import Payment from './Pages/Payment';


function App() {
  const userDetail = useSelector((state: any) => state.UserData.userData.data);

  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path={routes.LOGIN}>
            <Route index element={<Login />} />

            <Route path={routes.DASHBOARD}
              element={
                <AuthMiddleware>
                  <Dashboard />
                </AuthMiddleware>
              }
            />


            <>
              <Route path={routes.BANNER}
                element={
                  <AuthMiddleware>
                    <Banner />
                  </AuthMiddleware>
                } />

              <Route path={routes.PLAYLIST}
                element={
                  <AuthMiddleware>
                    <Playlist />
                  </AuthMiddleware>
                } />

              <Route path={routes.APP}
                element={
                  <AuthMiddleware>
                    <MobileApp />
                  </AuthMiddleware>
                } />

              <Route path={routes.USER}
                element={
                  <AuthMiddleware>
                    <User />
                  </AuthMiddleware>
                } />

              <Route path={routes.CATEGORIES}
                element={
                  <AuthMiddleware>
                    <Categories />
                  </AuthMiddleware>
                } />

              <Route path={routes.PAYMENT}
                element={
                  <AuthMiddleware>
                    <Payment />
                  </AuthMiddleware>
                } />


              <Route path={routes.MEDIA_VIEW}
                element={
                  <AuthMiddleware>
                    <MediaView />
                  </AuthMiddleware>
                } />

            </>



            <Route path={routes.SETTINGS}
              element={
                <AuthMiddleware>
                  <Setting />
                </AuthMiddleware>
              } />

          </Route>
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App
