import React, { useEffect, useState } from 'react'
import { ApiServices } from '../../services/apiServices';
import HeaderCmp from '../../components/HeaderCmp';
import SliderCmp from '../../components/SliderCmp';
import { ApiConfig } from '../../services/ApiConfigs';
import PlayListTable from './PlayListTable';
import EditPlaylist from './EditPlaylist';
import { successMsg } from '../../utils/ShowMessage';
import { useSelector } from 'react-redux';

const Playlist = () => {

    const [playListData, setPlaylistData] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editData, setEditData] = useState([]);
    const [flow, setFlow] = useState('');
    const [loader, setLoader] = useState(false);

    const userDetail = useSelector((state: any) => state.UserData.userData.data);

    useEffect(() => {
        getPlaylists()
    }, [])

    const getPlaylists = () => {
        setLoader(true)
        try {
            ApiServices('get', ApiConfig.PLAY_LIST).then((res: any) => {
                let tmp = res?.data?.data
                console.log(tmp, "PLAY_LIST response..", userDetail.id);
                setEditData([])
                setLoader(false)
                if (res.status == 200) {
                    if (userDetail?.role == 1) {
                        const userPlaylist = tmp.filter((playlist: any) => playlist.user_id == userDetail.id)
                        setPlaylistData(userPlaylist)
                    } else {
                        setPlaylistData(tmp)
                    }

                } else {
                    setPlaylistData([])
                }
            })
        } catch (error) {
            setLoader(false)
            console.log(error)
        }
    }

    //update banner
    const updatePlaylist = async (id: any, data: any) => {
        setLoader(true)
        console.log(id, "== update Playlist ==", data)
        try {
            await ApiServices('put', ApiConfig.PLAYLIST + data?.id, { status: id ? 1 : 0 }).then((res) => {
                console.log(res, "update Playlist response..");
                if (res.status == 200) {
                    getPlaylists();
                    successMsg("Update successfully.")
                }
            })
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <div>
            <HeaderCmp />
            <div className='d-flex'>
                <SliderCmp />
                <div className='p-5 w-100 '>
                    <div className='d-flex' style={{ backgroundColor: "#282A3A", padding: 15, borderRadius: 10, paddingTop: 20, }}>
                        <h5 style={{ color: "white", paddingLeft: 15 }}>Playlist</h5>
                    </div>
                    <div style={{ padding: 10 }}>

                        <PlayListTable
                            setData={setPlaylistData}
                            loader={loader}
                            setFlow={setFlow}
                            data={playListData}
                            getPlaylists={getPlaylists}
                            isModalOpen={isModalOpen}
                            setIsModalOpen={setIsModalOpen}
                            setEditData={setEditData}
                            updatePlaylist={updatePlaylist}
                        />
                    </div>
                    <EditPlaylist
                        editData={editData}
                        flow={flow}
                        getPlaylists={getPlaylists}
                        data={playListData}
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                    />
                </div>
            </div>
        </div>
    )
}

export default Playlist