import React, { useEffect, useState } from 'react';
import type { StatisticProps } from 'antd';
import { Card, Col, Row, Statistic } from 'antd';
import CountUp from 'react-countup';
import { ApiServices } from '../../services/apiServices';
import { ApiConfig } from '../../services/ApiConfigs';



const UserCount: React.FC = () => {

    const [userData, setUserData] = useState({
        teacher: 5,
        users: 10
    } as any)

    useEffect(() => {
        console.log(userData.users)
        console.log(userData.teacher)
    }, [userData])

    const getUsers = async () => {
        try {
            await ApiServices('get', ApiConfig.ALL_USERS).then((res) => {
                let tmp = res?.data?.data
                console.log(tmp, "users response from dashboard..")
                if (res.status == 200) {
                    var teacher: any = []
                    var users: any = []
                    tmp.map((res: any) => {
                        if (res.role == 1) {
                            teacher.push(res)
                        } else {
                            users.push(res)
                        }
                    })
                    console.log(teacher, "===", users)
                    setUserData({
                        teacher: teacher,
                        users: users
                    })
                } else {

                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getUsers()
    }, [])

    const formatter: StatisticProps['formatter'] = (value) => (
        <CountUp end={value as number} separator="," />
    );

    return (<Row gutter={2}>
        <Col span={6}>
            <Card title="Users" bordered={false} style={{ width: 300 }}>
                <Statistic title="" value={userData?.users?.length || 0} formatter={formatter} />
            </Card>
        </Col>
        <Col span={6}>
            <Card title="Teacher" bordered={false} style={{ width: 300 }}>
                <Statistic title="" value={userData?.teacher?.length || 0} precision={2} formatter={formatter} />
            </Card>
        </Col>
    </Row>)

};

export default UserCount;