import React, { useEffect, useState } from 'react';
import { Button, Image, Modal } from 'antd';
import PDFViewer from 'pdf-viewer-reactjs'


const ViewFileCmp = ({ isModalOpen, setIsModalOpen, data }) => {

    const [type, setType] = useState("")
    const [url, setUrl] = useState("")

    useEffect(() => {
        console.log(data, "click data");
        setType(data?.UploadMedium?.type || "")
        setUrl(data?.UploadMedium?.url || "")
    }, [data])

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const ImgViews = ({ src }) => {
        return (
            <Image
                width={200}
                height={150}
                src={src}
            />
        )
    }

    const VideoViews = ({ url, poster }) => {
        console.log(poster, "poster")
        return (
            <>
                {
                    poster ?
                        <>
                            <video controls width={600} height={500} poster={poster}>
                                <source src={url} type="video/webm" />
                            </video>
                        </>
                        :
                        <video controls width={600} height={500}>
                            <source src={url} type="video/webm" />
                        </video>
                }
            </>
        )
    }

    const RenderCmp = () => {
        if (type == "image/jpeg" || type == "image/jpg" || type == "image/png") {
            return <ImgViews src={url} />

        } else if (type == "video/mp4" || type == "audio/mpeg" || type == "video/quicktime") {
            return <VideoViews
                url={url}
                poster={data?.thumbnailMedia?.url}
            />

        } else if (type == "application/pdf" || type == "pdf") {
            return <PDFViewer
                width={800}
                css="asdf-wrapper"
                canvasCss="asdf-canvas"
                document={{
                    url: url,
                }}

            />

            // <PDFViewer
            //     height={600}
            //     css="asdf-wrapper"
            //     canvasCss="asdf-canvas"
            //     hideNavbar
            //     document={{
            //         url: url
            //     }}
            // />
        }
    }

    return (
        <>
            <Modal width={650} title="View File" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <RenderCmp />
            </Modal>
        </>
    );
};

export default ViewFileCmp;