import React, { useEffect, useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
} from "chart.js";
import dayjs from "dayjs";
import { ApiServices } from '../../services/apiServices';
import { ApiConfig } from '../../services/ApiConfigs';

ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Tooltip, Legend);

const PaymentChart = () => {
    const [loader, setLoader] = useState(false);
    const [datas, setDatas] = useState([] as any);

    const getPayments = async () => {
        setLoader(true)
        try {
            await ApiServices('get', ApiConfig.PAYMENT).then((res: any) => {
                let tmp = res?.data?.data
                console.log(tmp, "PAYMENT response..")
                setLoader(false)
                if (tmp) {
                    setDatas(tmp)
                }
            })
        } catch (error) {
            setLoader(false)
            console.log(error)
        }
    }

    useEffect(() => {
        getPayments()
    }, [])

    // Process data to group by month
    const chartData = useMemo(() => {

        const dataByMonth = datas.reduce((acc, curr) => {
            // Format date to "YYYY-MM" (e.g., "2024-08")
            const month = dayjs(curr.createdAt).format("YYYY-MM");
            if (!acc[month]) {
                acc[month] = 0;
            }
            acc[month] += curr.price; // Summing prices per month
            return acc;
        }, {});

        const labels = Object.keys(dataByMonth); // X-axis: Month labels
        const prices = Object.values(dataByMonth); // Y-axis: Total price per month

        return {
            labels,
            datasets: [
                {
                    label: "Total Prices by Month",
                    data: prices,
                    fill: false,
                    borderColor: "rgb(75, 192, 192)",
                    tension: 0.4,
                },
            ],
        };

    }, [datas]);

    const options = {
        responsive: true,
        plugins: {
            tooltip: {
                mode: "index",
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: "Month",
                },
            },
            y: {
                title: {
                    display: true,
                    text: "Total Price",
                },
            },
        },
    };


    return (
        <div className="pt-4">
            <h5>Payment:</h5>
            <Line data={chartData} options={options} />
        </div>
    );
};

export default PaymentChart;


