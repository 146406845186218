import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { routes } from "../utils/routes.config"
import { handleCheckAuth } from "../utils/handleAuth"

const AuthMiddleware = ({ children }: any) => {
    const authUser = handleCheckAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if (!authUser) {
            navigate(routes.LOGIN)
        }
    }, [authUser])

    return children
}

export default AuthMiddleware